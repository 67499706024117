/* font-face */

@font-face{font-display:swap;font-family:'Gilroy';src:url('../fonts/gilroy/Gilroy-Extrabold.woff2') format('woff2'),url('../fonts/gilroy/Gilroy-Extrabold.woff') format('woff');font-weight:bold;font-style:normal;}
@font-face{font-display:swap;font-family:'Gilroy';src:url('../fonts/gilroy/Gilroy-ExtraboldItalic.woff2') format('woff2'),url('../fonts/gilroy/Gilroy-ExtraboldItalic.woff') format('woff');font-weight:bold;font-style:italic;}
@font-face{font-display:swap;font-family:'Gilroy';src:url('../fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),url('../fonts/gilroy/Gilroy-Medium.woff') format('woff');font-weight:500;font-style:normal;}
@font-face{font-display:swap;font-family:'Gilroy';src:url('../fonts/gilroy/Gilroy-MediumItalic.woff2') format('woff2'),url('../fonts/gilroy/Gilroy-MediumItalic.woff') format('woff');font-weight:500;font-style:italic;}

/* general */

*,*:after,*:before{position:relative;box-sizing:border-box;}
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video,button,hr,input,textarea{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}
a,button{transition:var(--transi);cursor:pointer;color:inherit;}
button{border:0;padding:0;background:none;display:inline-block;}
a,a:hover,button,button:hover,*:focus,*:active{text-decoration:none;outline:none;}
body{line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:subpixel-antialiased;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;-webkit-tap-highlight-color:transparent;}
ol,ul{list-style:none;}
blockquote,q{quotes:none;}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}
table{border-collapse:collapse;border-spacing:0;}
strong{font-weight:bold}
img,svg,video{vertical-align:bottom;max-width:100%;height:auto;}
textarea{resize:none;}
picture{display:block;}
picture img{max-width:100%;width:100%;}
.swiper-slide{flex:1 0 auto;}
.swiper-wrapper{display:flex;flex-wrap:nowrap;height:100%;}

/* var */
:root{
  --ff:'Gilroy', sans-serif;
  --fs:calc(13px + 2 * (100vw - 320px) / 1080);
  --fs_title:calc(20px + 15 * (100vw - 320px) / 1080);
  --cubic:cubic-bezier(.305,.045,.355,1);
  --transi:.25s;
  --ct:calc(800px + 300 * (100vw - 320px) / 1080);
  --opac:.5;
  --blur:blur(5px);
  --bg_rgb:7,3,1;
  --bg_rgb_secondary:40,40,40, 0.9;
  --bg:rgb(var(--bg_rgb));
  --bg_blur:rgba(var(--bg_rgb),var(--opac));
  --txt_rgb:252,248,241;
  --txt:rgb(var(--txt_rgb));
  --primary_rgb:185,83,254;
  --primary:rgb(var(--primary_rgb));
  --secondary_rgb:155,96,252;
  --secondary:rgb(var(--secondary_rgb));
  --tertiary_rgb:117,63,255;
  --tertiary:rgb(var(--tertiary_rgb));
  --primary_txt:#fff;
  --green:58,255,90;
  --ts:0 2px 5px rgba(0,0,0,1), 0 2px 15px rgba(0,0,0,.5);
  --bx:0 2px 5px rgba(0,0,0,.8), 0 0 10px rgba(0,0,0,.5), 0 0 10px #000 inset, 0 -2px 0 rgba(0,0,0,.2) inset;
  --radius:calc(8px + 2 * (100vw - 320px) / 1080);
  --pad:calc(25px + 15 * (100vw - 320px) / 1080);
}

@supports not (backdrop-filter:blur(0px)) {
  :root{
    --opac:.8;
  }
}

/* scrollbar styling */
*{scrollbar-color:var(--primary) var(--bg);scrollbar-width:thin;}
*::-webkit-scrollbar{width:3px;background-color:var(--bg);}
*::-webkit-scrollbar-thumb{background-color:var(--primary);}

/* selection styling */
*::-moz-selection{background-color:rgba(var(--primary_rgb),.8);color:var(--txt);text-shadow:0 0 0;}
*::selection{background-color:rgba(var(--primary_rgb),.8);color:var(--txt);text-shadow:0 0 0;}

/* selection placeholder */
::-webkit-input-placeholder{color:inherit;opacity:.3;}
::-moz-placeholder{color:inherit;opacity:.3;}
::-ms-input-placeholder{color:inherit;opacity:.3;}

/*----------------------------------------------------< PRESET APP >---------------------------------------------------*/

/* * */

html, body{font-size:calc(13px + 3 * (100vw - 320px) / 1080);color:var(--txt);line-height:1;font-family:var(--ff);font-weight:500;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
html{background:var(--bg);background-size:100% auto;}

body:before{opacity:.3;content:'';position:fixed;left:0;top:0;width:100%;height:100%;background:url(../img/bg-ix.jpg) center center no-repeat;background-size:cover;}

.ct{width:calc(800px + 300 * (100vw - 320px) / 1080);max-width:85%;margin:auto;}

.bt{overflow:hidden;--svg:var(--primary);font-size:16px;box-shadow:0 0 0 1px rgba(255,255,255,.05), 0 3px 10px rgba(0,0,0,.2);border-radius:var(--radius);backdrop-filter:var(--blur);font-weight:bold;padding:17px 25px 14px;background-color:var(--bg_blur);display:inline-flex;align-items:center;justify-content:center;text-align:center;}
.bt:not(.bt-nh):active{transform:scale(.95);}
.bt:not(.bt-p):hover, .bt:not(.bt-p).bt-act{--svg:var(--primary_txt);background-color:var(--secondary);box-shadow:0 0 0 1px rgba(255,255,255,0), 0 3px 10px rgba(var(--primary_rgb),.2);}
.bt:not(.bt-p).bt-act{background-color:var(--primary);box-shadow:0 0 0 1px rgba(255,255,255,0), 0 3px 10px rgba(var(--primary_rgb),.2);}
.bt svg{transition:var(--transi);width:15px;top:-1px;margin-left:7px;margin-right:-3px;fill:var(--svg);}

.bt-p{text-shadow:0 1px 2px rgba(0,0,0,.3);box-shadow:0 0 25px rgba(var(--primary_rgb),.8);}
.bt-p:not(.bt-nh):hover{box-shadow:0 0 25px rgba(var(--tertiary_rgb),.8);}
.bt-p svg{width:20px;--svg:var(--primary_txt);}
.bt-p:before{transition:var(--transi);content:'';background:linear-gradient(to right, var(--primary), var(--secondary), var(--tertiary));width:200%;height:100%;position:absolute;left:0;top:0;z-index:0;}
.bt-p:not(.bt-nh):hover{background:transparent;}
.bt-p:not(.bt-nh):hover:before{transform:translateX(-48%);}

*:disabled{pointer-events:none!important;}

.notif{font-size:14px;border-radius:var(--radius);padding:10px;text-align:left;margin-top:15px;background-color:#201d1c;}
.notif.danger{background-color:#fa4444;}
.notif.success{background-color:rgb(var(--green));color:#201d1c;}
.notif.pending{background-color:#446efa;}

.modalOverlay{position:fixed;left:0;top:0;width:100%;height:100%;background-color:rgba(0,0,0,.15);z-index: 9999;opacity:1}
.modal{position:fixed;padding:0.5rem;left:50%;top:50%;transform:translate(-50%,-50%);max-width:52rem;min-width: 0rem;display:flex;gap:0.4rem;background-color:var(--bg_blur);border-radius:var(--radius);backdrop-filter:var(--blur);box-shadow:0 0 0 1px rgba(255,255,255,.05), 0 3px 10px rgba(0,0,0,.2);z-index: 9999;opacity:1;}
.wallet{width:23.75rem;height: 10rem; border-radius: var(--radius); background-color: var(--bg_blur);backdrop-filter:var(--blur);transition:all,200ms;display:flex;flex-direction:column;justify-content:center;align-items:center;gap:0.4rem;}
.wallet:hover{background-color: rgba(var(--bg_rgb_secondary));cursor:pointer; backdrop-filter:var(--blur);}
.wallet .wallet__title{font-size:1.6rem; font-weight:600; color:var(--txt);}
.wallet .wallet__description{font-size:1rem; color:var(--txt);}


/*----------------------------------------------------< APP >---------------------------------------------------*/

/* header */

.top-h{position:fixed;left:0;top:0;width:40%;min-width:calc(250px + 150 * (100vw - 320px) / 1080);z-index:10;}
.top-h-r{position:fixed;right:0;top:0;width:60%;z-index:5;min-width:calc(350px + 150 * (100vw - 320px) / 1080);}
#h{padding:0 calc(15px + 5 * (100vw - 320px) / 1080);background-color:var(--bg_blur);box-shadow:0 0 0 1px rgba(255,255,255,.05), 0 3px 10px rgba(0,0,0,.2);border-radius:0 0 var(--radius) var(--radius);backdrop-filter:var(--blur);width:var(--ct);z-index:9999;max-width:calc(100% - var(--pad)*2);display:flex;align-items:center;justify-content:space-between;position:fixed;transform:translateX(-50%);top:0;left:50%;}
#h a{pointer-events:auto;}
#h .lg{width:calc(200px + 30 * (100vw - 320px) / 1080);display:block;transform:translateY(-12%);}
#h .l{display:flex;align-items:center;}

/* header */

#n ul{display:flex;align-items:center;}
#n ul li{margin-left:calc(10px + 5 * (100vw - 320px) / 1080);}

/* main */

#m{align-items:flex-start;max-width:calc(100% - var(--pad)*2);display:flex;width:var(--ct);margin:auto;z-index:500;padding:calc(150px + 25 * (100vw - 320px) / 1080) 0 calc(50px + 10 * (100vw - 320px) / 1080);}
#m .m-r{z-index:-1;bottom:0;right:0;padding:calc(30px + 15 * (100vw - 320px) / 1080);padding-left:calc(50px + 30 * (100vw - 320px) / 1080);display:flex;align-items:center;}
#m .m-r .kxa{width:calc(150px + 20 * (100vw - 320px) / 1080);animation:kxa 3s infinite linear;}
#m .m-r .lock{width:calc(35px + 15 * (100vw - 320px) / 1080);position:absolute;bottom:0;left:50%;transform:translate(-50%, 40%);}

@keyframes kxa{
  0%{transform:translateY(0);}
  50%{transform:translateY(-5%);}
  100%{transform:translateY(0);}
}

/* app */

#app{flex:1;background-color:var(--bg_blur);box-shadow:0 0 0 1px rgba(255,255,255,.05), 0 3px 10px rgba(0,0,0,.2);border-radius:var(--radius);backdrop-filter:var(--blur);}
#app .app-c{padding:calc(25px + 15 * (100vw - 320px) / 1080);}
#app .app-c > * + *{margin-top:calc(15px + 5 * (100vw - 320px) / 1080);}
#app h1{font-weight:bold;font-size:calc(20px + 3 * (100vw - 320px) / 1080);}
#app h1 strong{color:var(--primary);}
#app h2{font-weight:bold;font-size:calc(16px + 2 * (100vw - 320px) / 1080);}
#app .intro{font-size:calc(15px + 2 * (100vw - 320px) / 1080);line-height:1.3;margin-top:calc(10px + 3 * (100vw - 320px) / 1080);}
#app .p{font-size:calc(13px + 1 * (100vw - 320px) / 1080);line-height:1.5;opacity:.7;}
#app .rw{--n:6;--g:calc(3px + 5 * (100vw - 320px) / 1080);margin:calc(10px + 5 * (100vw - 320px) / 1080) calc(var(--g)*-1) 0;display:flex;flex-wrap:wrap;}
#app .rw li{padding:var(--g);width:calc(100% / var(--n));}
#app .rw div{background:var(--bg);border-radius:var(--radius);}
#app .alert{font-size:14px;font-weight:bold;padding:16px 12px 12px;border-radius:var(--radius);left:0;top:0;width:100%;text-align:center;}
#app .alert.base{background-color:var(--bg);}
#app .alert.success{background-color:rgb(var(--green));color:#201d1c;}

.fm{--g:calc(10px + 5 * (100vw - 320px) / 1080);}
.fm fieldset{padding:calc(var(--g)*1.5) calc(var(--g)*2);border:1px solid rgba(255,255,255,.1);border-radius:var(--radius);}
.fm fieldset + fieldset{margin-top:15px;}
.fm legend{background-color:var(--bg);text-transform:uppercase;font-size:11px;border-radius:var(--radius);padding:3px 8px;font-weight:bold;}
.fm .ins{display:flex;width:calc(100% + var(--g)*2);margin:calc(var(--g)*-1);}
.fm .in{width:50%;padding:var(--g);}
.fm .in label{font-weight:bold;font-size:calc(12px + 1 * (100vw - 320px) / 1080);}
.fm .in label small{opacity:.7;font-style:italic;font-weight:normal;}
.fm .in input{color:var(--primary_txt);margin-top:7px;padding:15px;font-weight:bold;width:100%;display:flex;align-items:center;background-color:var(--bg_blur);box-shadow:0 0 0 1px rgba(255,255,255,.05), 0 3px 10px rgba(0,0,0,.2);border-radius:var(--radius);backdrop-filter:var(--blur);}
.fm .in input:disabled{color:var(--primary);}
.fm .in input:not(:disabled){box-shadow:0 0 0 1px rgba(var(--primary_rgb),.2), 0 3px 10px rgba(0,0,0,.2);}
.fm .inm{flex:1;}
.fm .inx{width:auto;display:flex;align-items:flex-end;}
.fm .stk{border-color:rgba(var(--primary_rgb),.8);}
.fm .stk legend{background:linear-gradient(to right, var(--primary), var(--secondary));}
.fm .stk .copy-block{line-height:120%; margin: 10px 0 10px 0;}
.fm .stk-g{border-color:rgba(var(--green),.5);box-shadow:0 0 15px rgba(var(--green),.2), 0 0 15px rgba(var(--green),.3) inset;}
.fm .stk-g legend{background-color:rgb(var(--green));color:var(--bg);box-shadow:0 0 25px rgba(var(--green),1);}
.fm .stk-g input{color:rgb(var(--green))!important;box-shadow:0 0 0 1px rgba(var(--green), .1)!important;}
.fm .stk-g .bt:disabled{background-color:rgb(var(--green))!important;color:var(--bg);cursor:not-allowed;opacity:.5;}
.fm .stk-g .bt{background-color:rgb(var(--green));color:var(--bg);}
.fm .stk-g .bt:hover{box-shadow:0 0 25px rgba(var(--green),1);}
.fm .stk-g .bt small{display:block;font-size:10px;font-weight:normal;}
.fm .stk-g .bt strong{font-size:14px;}

/* nft card */
.nftPage h1{margin: 15px; }
.nftPage h3{margin: 15px; }
.nftContainer{display: flex; flex-wrap: wrap; padding-left: 10px; }
.nftContainer .nftCard{border: 1px solid #ababab; border-radius: 10px; margin-right: 10px; margin-bottom: 10px}
.nftContainer .nftCard .imageContainer{width: 100%; border-top-left-radius: 10px; border-top-right-radius: 10px}
.nftContainer .nftCard .imageContainer img {border-top-left-radius: 10px; border-top-right-radius: 10px;}
.nftContainer .nftCard .details{padding: 10px; }
.nftContainer .nftCard .details div{margin-bottom: 5px; }
.nftContainer .nftCard .details .header-container{ display: flex; font-size: 13px; color: #ababab }
.nftContainer .nftCard .details .header-container span{width: 125px;}
.nftContainer .nftCard .details .header-container .right{text-align: right;}
.nftContainer .nftCard .details .description{font-size: 14px;}

.nftContainer .nftCard .displaySendToGame { background-color: rgba(0,0,0, 0.5); border-top-left-radius: 10px; border-top-right-radius: 10px; position: absolute; z-index: 1; height: 100%; width: 100% }
.nftContainer .nftCard .displaySendToGame .attributes { height: calc(100% - 37px ); line-height: calc(100% - 37px ); padding: 5px; color: white; font-size: 0.85em; }
.nftContainer .nftCard .displaySendToGame .attributes ul { display: inline-block; vertical-align: bottom; }
.nftContainer .nftCard .displaySendToGame .attributes ul li { line-height: 14px; }
.nftContainer .nftCard .displaySendToGame button { opacity: 1; margin-left: 5px; margin-right: 5px; width: calc(100% - 10px); height: 32px }

.bundleContainer {display: inline-block; text-align: center}
.bundleContainer .bundleCard {border: 1px solid #ababab; border-radius: 10px; margin-right: 10px; margin-bottom: 10px; padding: 20px}
.bundleContainer .bundleCard .bundleCardTitle { font-size: 1.2em; margin-bottom: 20px; }
.bundleContainer .bundleCard .bundleCardContent {}

@media screen and (max-width:600px)  {.nftContainer .nftCard { width: calc((100% - 20px) / 2);}}
@media screen and (min-width:600px)  {.nftContainer .nftCard { width: calc((100% - 30px) / 3);}}
@media screen and (min-width:900px)  {.nftContainer .nftCard { width: calc((100% - 40px) / 4);}}
@media screen and (min-width:1200px) {.nftContainer .nftCard { width: calc((100% - 50px) / 5);}}
@media screen and (min-width:1500px) {.nftContainer .nftCard { width: calc((100% - 60px) / 6);}}
@media screen and (min-width:2020px) {.nftContainer .nftCard { width: calc((100% - 70px) / 7);}}

.mint {display: flex;justify-content: space-between;}
.btmi{animation:mint 2s infinite linear;display:inline-block;}

@keyframes mint{
  0%{transform:rotate(3deg) scale(1);}
  50%{transform:rotate(-3deg) scale(1.2);}
  100%{transform:rotate(3deg) scale(1);}
}

.copy{position:absolute;font-size:11px;bottom:-25px;opacity:.4;left:50%;transform:translateX(-50%);}


.mint-table {
  border:1px solid var(--primary);
  border-collapse: separate;
  border-radius: 12px;
  overflow:hidden;
  width: 100%;
}
.mint-table thead {
  color: white;
}
.mint-table table {
  background-color: var(--primary);
}

.mint-table td, th {
  border-left:solid var(--primary) 1px;
  border-top:solid var(--primary) 1px;
}

.mint-table th {
  border-top: none;
  padding:5px;
  background: var(--primary);
  color: white;
  font-weight:bold;
  font-size:calc(13px + 2 * (100vw - 320px) / 1080);
}
.mint-table td {
  padding: 6px 16px 6px 16px;
}

.mint-table td:first-child, th:first-child {
  border-left: none;
}

/*----------------------------------------------------< MEDIA QUERIES >---------------------------------------------------*/

@media screen and (max-width:980px){
  #m .m-r{display:none;}

  #h .bt span{display:none;}
  #h .bt svg{margin-left:0;top:0;left:-1px;transform:scale(1.2);}
  #h a.bt{--w:calc(45px + 5 * (100vw - 320px) / 1080);padding:0;width:var(--w);height:var(--w);}
}
@media screen and (max-width:700px){
  #h .bt-has{display:none;}
}
@media screen and (max-width:600px){
  #app .rw{--n:3;}
  .fm .ins{display:block;}
  .fm .in{width:100%;}
}
@media screen and (max-width:500px){
  #h .lg{width:calc(150px + 20 * (100vw - 320px) / 1080);}
  #h a.bt{--w:calc(35px + 3 * (100vw - 320px) / 1080);}
  #h .bt svg{transform:scale(1);}

  #m{padding-top:calc(90px + 15 * (100vw - 320px) / 1080);}
}
@media screen and (max-width:360px){
  #app .rw{--n:2;}
}
